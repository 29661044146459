import React, { useState } from "react";
import AboutUsComponent from "../upcoming_events/about_us_component";
// import Sport from "../../../assets/images/menu/hockey.jpg";
// import Kabbadi from "../../../assets/images/menu/kabaddi.jpg";
// import Udhaya from "@assets/images/menu/news/CM TROPHY (1).jpg";
import { Box, Hidden, Text, VStack } from "native-base";
import AboutUsHistoryContents from "./about_us _contents";
import MenuBar from "../banner/menubar";
import { useTranslation } from "react-i18next";
import ExecutiveContents from "./executive_contents";
import PolicyNote from "./policy_note";
import BackgroundWaterMarkLogo from "@views/pages/home/background_water_mark_logo";
import Note from "@assets/images/menu/note.png";
import running_game from "@assets/images/menu/running_game.jpg";
import hockey_game from "@assets/images/menu/hockey_game.jpg";
import Udhaya_image from "@assets/images/Deputy CM.png";

import Womens_Kabbadi from "@assets/images/menu/WomensKabbadi.jpg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterItems } from "@helpers/constants";
import PolicyNoteDemand from "./policy_note_demand";
import Low from "@assets/images/menu/law-book.png";
import AboutUsLoading from "./about_us_loading";
import { get_cms_aboutus_history_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_aboutus_history_query";
import { get_cms_executive_committee_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_executive_committee_query";
import { get_cms_by_laws_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_by_laws_query";
import { get_cms_policy_note_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_policy_note_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_administrative_setup_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_administrative_setup_query";
import AdministrativeSetup from "./administrative_setup";
import Administrative from "@assets/images/menu/people.png";

const AboutUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [about_us_data, setAboutUsData] = useState([]);
  const [executive_data, setExecutiveData] = useState([]);
  const [policy_data, setPolicyData] = useState([]);
  const [administrative_data, setAdministrativeData] = useState([]);
  const [laws_data, setLawsData] = useState([]);

  const { items: laws_items, loading: law_items_loading } =
    useDynamicSelector("get_cms_by_laws");

  const { items: administrative_items, loading: administrative_loading } =
    useDynamicSelector("get_cms_administrative_setup");

  const { items: policy_items, loading: policy_note_loading } =
    useDynamicSelector("get_cms_policy_note");

  const { items: executive_items, loading: executive_loading } =
    useDynamicSelector("get_cms_executive_committee");
  const { items: about_us_items, loading: history_loading } =
    useDynamicSelector("get_cms_about_history");
  const get_about_us_history = () => {
    let keys = [{ key: "get_cms_about_history", loading: true }];
    let variables = {
      json: {
        system_cms_key: "about_us_history",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_aboutus_history_query, variables));
  };

  const get_policy_note = () => {
    let keys = [{ key: "get_cms_policy_note", loading: true }];
    let variables = {
      json: {
        system_cms_key: "policy_note",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_policy_note_query, variables));
  };

  useEffect(() => {
    let values = FilterItems(executive_items);
    setExecutiveData(values);
  }, [executive_items]);
  useEffect(() => {
    let values = FilterItems(about_us_items);
    setAboutUsData(values);
  }, [about_us_items]);
  useEffect(() => {
    let values = FilterItems(policy_items);
    setPolicyData(values);
  }, [policy_items]);
  useEffect(() => {
    let values = FilterItems(laws_items);
    setLawsData(values);
  }, [laws_items]);
  useEffect(() => {
    let values = FilterItems(administrative_items);
    setAdministrativeData(values);
  }, [administrative_items]);

  const get_executive_committee = () => {
    let keys = [{ key: "get_cms_executive_committee", loading: true }];
    let variables = {
      json: {
        system_cms_key: "executive_committee",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_executive_committee_query, variables)
    );
  };
  const get_by_laws = () => {
    let keys = [{ key: "get_cms_by_laws", loading: true }];
    let variables = {
      json: {
        system_cms_key: "by_laws",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_by_laws_query, variables));
  };

  const get_administrative_setup = () => {
    let keys = [{ key: "get_cms_administrative_setup", loading: true }];
    let variables = {
      json: {
        system_cms_key: "administrative_setup",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_administrative_setup_query, variables)
    );
  };
  useEffect(() => {
    get_about_us_history();
    get_executive_committee();
    get_policy_note();
    get_by_laws();
    get_administrative_setup();
  }, []);
  const contents = [
    {
      Img1: running_game,
      // Img2: "https://v-lfa-assets.blr1.vultrobjects.com/01ba9770-6943-450c-9336-0048f0ffa2d4.jpg",
      Img2: Udhaya_image,
      Img3: hockey_game,
      Img4: Womens_Kabbadi,
      title: t("about_sdat"),
      // header: "Hon'ble Minister for Youth Welfare and Sports Development",
      content: t("the_sports_development"),
    },
  ];

  const Details = {
    name: t("policy_note"),
    description: t("youth_welfare_and_sports_development"),
    icon: Note,
  };

  const by_laws_details = {
    name: t("bye_laws"),
    description: t("youth_welfare"),
    icon: Low,
  };

  const administrative_details = {
    name: t("administrative_setup"),
    description: t("youth_welfare_and_sports_development"),
    icon: Administrative,
  };
  let sortedArray = executive_data?.sort(
    (a, b) => a?.profile?.row_number - b?.profile?.row_number
  );
  return (
    <>
      {executive_loading ||
      history_loading ||
      policy_note_loading ||
      law_items_loading ? (
        <AboutUsLoading />
      ) : (
        <VStack>
          <Box variant={"tender_page_menubox"} height={"auto"}>
            <Box height={"120px"}>
              <MenuBar />
              <Hidden only={["lg", "xl", "2xl", "3xl"]}>
                <Box variant={"xs_size_menu_headline"}>
                  <Text variant={"xs_size_menu_headline_one"}>
                    {t("header_content_one")}
                  </Text>
                  <Text variant={"xs_size_menu_headline_two"}>
                    {t("sports_development_authority_of_tamilnadu")}
                  </Text>
                </Box>
              </Hidden>
            </Box>
            <Hidden only={["xs", "sm"]}>
              <Text variant={"page_title"}>{t("about_us")}</Text>
            </Hidden>
          </Box>
          <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
            <Box>
              <Text
                variant={"page_title"}
                color={"black"}
                paddingBottom={"0px"}
              >
                {t("about_us")}
              </Text>
            </Box>
          </Hidden>
          <Box>
            <BackgroundWaterMarkLogo />
            <Box mb={"70px"}>
              {contents.map((s) => {
                return (
                  <div style={{ width: "100%" }}>
                    <AboutUsComponent
                      title={s.title}
                      header={s.header}
                      content={s.content}
                      Img1={s.Img1}
                      Img2={s.Img2}
                      Img3={s.Img3}
                      Img4={s.Img4}
                      xs={"column"}
                      md={"row"}
                      lg={"row"}
                    />
                  </div>
                );
              })}
            </Box>
          </Box>
          <Box marginTop={["", "", "", "", "120px", "235px"]} mb={"30px"}>
            <AboutUsHistoryContents about_us_data={about_us_data} />
          </Box>
          <Box>
            <ExecutiveContents
              executive_data={sortedArray.sort(
                (a, b) => Number(a?.order?.value) - Number(b?.order?.value)
              )}
            />
          </Box>
          <Box
            padding={{ xs: "30px", lg: "30px" }}
            paddingRight={{ xs: "45px", md: "30px", lg: "60px" }}
          >
            <PolicyNote Details={by_laws_details} laws_data={laws_data} />
          </Box>
          <Box
            padding={{ xs: "30px", lg: "30px" }}
            paddingRight={{ xs: "45px", md: "30px", lg: "60px" }}
          >
            <AdministrativeSetup
              Details={administrative_details}
              administrative_data={administrative_data}
            />
          </Box>
          <Box
            // mb={"50px"}
            padding={{ xs: "30px", lg: "30px" }}
            paddingRight={{ xs: "45px", md: "30px", lg: "60px" }}
          >
            <PolicyNoteDemand Details={Details} policy_data={policy_data} />
          </Box>
        </VStack>
      )}
    </>
  );
};

export default AboutUs;
