import React, { useState } from "react";
import { Box, Center, HStack, Text, Hidden, Stack, VStack } from "native-base";
import { Image } from "native-base";
import { Button } from "native-base";
//import { FaGreaterThan } from "react-icons/fa";
import { MdOutlineArrowForwardIos } from "react-icons/md";
// import { Rotate } from "hamburger-react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import Quotes from "@assets/images/menu/quote (1).png";
import DoubleQuotes from "@assets/images/menu/double-quotes (1).png";

const AboutUsComponent = (props) => {
  const [model_open, setModelOpen] = useState(false);
  const { content, Img1, Img2, Img3, Img4, title, header, index } = props;

  const { t } = useTranslation();
  const handleModelOpen = () => {
    setModelOpen(true);
  };
  const handleClose = () => {
    setModelOpen(false);
  };
  return (
    <>
      <Box
        pl={{ lg: 10 }}
        pr={{ lg: 10 }}
        mt={20}
        minHeight={{
          xs: "411px",
          md: "390px",
          xl: "390px",
          lg: "500px",
          "2xl": "640px",
          "3xl": "640px",
        }}
      >
        <Stack
          // style={{ gap: "80px" }}
          justifyContent="space-evenly"
          display={"flex"}
          flexDirection={{ xs: props?.xs, md: props?.md, lg: props?.lg }}
        >
          <Box
            variant="HomePageContentBox"
            zIndex={-1}
            flex={1}
            alignItems={{
              base: "center",
              xs: "center",
              md: "start",
              lg: "start",
            }}
            minH={{ base: "300px", xs: "400px", lg: "550px", xl: "500px" }}
          >
            <Box variant="HomePageFirstBox">
              <Image variant="ImageFirst" source={Img1} />
            </Box>
            <Box variant="HomePageSecondBox">
              <Image variant="ImageSecond" source={Img2} style={{resizeMode:"cover"}} />
            </Box>
            <Box variant="HomePageThirdBox">
              <Image variant="ImageThird" source={Img3} />
            </Box>
            <Box variant="HomePageFourBox">
              <Image variant="ImageFourth" source={Img4} />
            </Box>
          </Box>
          <Box
            flex={1}
            justifyContent={"center"}
          // marginTop={{ xs: "80", md: "56", lg: "48" }}
          // marginLeft={{ xs: "-56", md: "100px", lg: "48", xl: "56" }}
          >
            <VStack
              space={10}
              width={{
                base: "0px",
                xs: "300px",
                sm: "310px",
                md: "360px",
                lg: "530px",
                xl: "600px",
                "2xl": "650px",
              }}
              alignSelf={{ base: "", xs: "center", lg: "flex-end", xl: "" }}
            >
              <Box variant="HomePageRightBox">
                <Text variant="HeadLineTextOne">{title}</Text>
                <Text
                  variant="HeadLineTextTwo"
                  paddingLeft={["", "20px", "", "", "", ""]}
                  paddingRight={["", "20px", "", "", "", ""]}
                >
                  {header}
                </Text>

                <Box
                  variant="HomePageDescriptionBox"
                  width={"100%"}
                  paddingRight={[
                    "20px",
                    "20px",
                    "20px",
                    "20px",
                    "20px",
                    "20px",
                  ]}
                >
                  <HStack
                    justifyContent={"flex-start"}
                    width={"100%"}
                    mr="200px"
                  >
                    <Image size={"100px"} src={Quotes} />
                  </HStack>
                  <HStack justifyContent={"flex-start"} width={"100%"}>
                    <Text bold fontSize={"var(--font-size-14)"}>
                      {t('message')}
                    </Text>
                  </HStack>

                  <Text
                    variant="DescriptionText"
                    // padding={{ xs: "3rem", lg: "0" }}
                    textAlign={{ xs: "justify" }}
                    mt={"20px"}
                  >
                    {content}
                  </Text>
                  <HStack justifyContent={"flex-end"} width={"100%"} ml="180px">
                    <Image size={"100px"} src={DoubleQuotes} />
                  </HStack>
                </Box>
              </Box>
              {title == t("about_sdat") ? (
                <Box variant="HomePageButtonBox">
                  <>
                    <Button
                      size={"md"}
                      rounded={"lg"}
                      variant={"solid"}
                      width={"32"}
                      height={"12"}
                      // fontSize={"var(--font-size-14)"}
                      backgroundColor={"#3B1B7E"}
                      fontFamily={"'Poppins', sans-serif"}
                      onPress={handleModelOpen}
                      style={{ fontSize: "var(--font-size-14) !important" }}
                    >

                      {t("know_more")}
                    </Button>
                  </>
                </Box>
              ) : (
                ""
              )}
            </VStack>
          </Box>
          {/* <Hidden only={["xs", "sm"]}>
          <Box variant="box_shape" style={{ transform: "skew(-10deg)" }}>
            <Button
              width={"60px"}  
              height={"80px"}
              backgroundColor="white"
              shadow={"4"}
              style={{
                boxShadow: "0px 10px 10px 5px  rgb(0 0 0 / 9%) ",
              }}
            >
              <MdOutlineArrowForwardIos color="rgb(0 0 139)" size={20} />
            </Button>
          </Box>
        </Hidden> */}
        </Stack>
      </Box>
      <Modal
        open={model_open}
        onCancel={handleClose}
        footer={null}
        className="about-model"
      >
        <Box>
          <Box>
            <Text variant="HeadLineTextOne">{props.title}</Text>
          </Box>
          <Box>
            <Text>{t("the_sports_development")}</Text>
            <Text bold>{t("in_this_endeavour")}</Text>
            <Text>
              <ul>
                <li>{t("providing_opportunities")}</li>
                <li>{t("develop_identified")}</li>
                <li>{t("bringing_competition")}</li>
                <li>{t("establishing_sports_infrastructure")}</li>
                <li>{t("to_develop")}</li>
              </ul>
            </Text>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default AboutUsComponent;
